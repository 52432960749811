import { IExportClient } from '../common/interfaces'
import { apiUrl } from '../config/api'
import { IFilters, IFilterStatus } from '../modules/movements/components/tabs/RescueTab'

export const RescueService = () => {
  const listRescues = (
    page: number | string,
    limit: number | string,
    search: string,
    filters: IFilters
  ) =>
    apiUrl
      .post(`rescue/list?page=${page}&limit=${limit}`, { search, filter: filters })
      .then((res: any) => res.data)

  const changeStatusRescue = (status: IFilterStatus, selectedRescues: number[], comment: string) =>
    apiUrl
      .post(`/rescue/change-status`, {
        ids: selectedRescues,
        status,
        comment,
      })
      .then((res: any) => res.data)

  const changeStatusSendSpectrumRescue = (selectedRescues: number[]) =>
    apiUrl.post(`/rescue/send-spectrum-rescue`, {
      ids: selectedRescues
    })
    .then((res: any) => res.data)

  const listRescueDetails = (rescue_id: number | string) =>
    apiUrl.get(`/rescue/list-details/${rescue_id}`).then((res: any) => res.data)

  const getRangeData = (dateFilter: IFilters) =>
    apiUrl.post(`rescue/list`, { filter: dateFilter }).then(async (res: any) => {
      return res.data
    })

  const exportRescue = (
    req: IExportClient
  ) =>
    apiUrl
      .post(`/rescue/export`, {
        search: req.search,
        filter: req.filter,
      },{
        responseType: 'blob',
        headers: {
          'Document-Type': 'xlsx'
        }
      })
      .then((res) => res.data)

  return {
    listRescues,
    listRescueDetails,
    changeStatusRescue,
    changeStatusSendSpectrumRescue,
    getRangeData,
    exportRescue
  }
}
