export const openFile = (res: any, name = 'prudential-document') => {
    const blob = new Blob([res], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    // Creacion de URL y descarga
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = name;
    link.click();

    window.URL.revokeObjectURL(downloadUrl);
};
