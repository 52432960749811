/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { SearchInput } from '../../../../common/components/SearchInput'
import { BackofficePermissionsEnum as Permissions } from '../../../../common/enums/backOfficePermissionsEnum'
import { useDebounce } from '../../../../common/hooks/useDebounce'
import usePermissions from '../../../../common/hooks/usePermissions'
import { useStateCallback } from '../../../../common/hooks/useStateCallback'
import { IExportClient, IPaymentStatusEnum } from '../../../../common/interfaces'
import { customSwal } from '../../../../config/swal'
import { useSolicitudes } from '../../hooks/useSolicitudes'
import { Export } from '../Export'
import { Filter } from '../Filter'
import { StatusList } from '../StatusList'
import { SolicitudesTable } from '../tables/SolicitudesTable'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'

export type IFilterStatus = '-1' | '0' | '1' | '2' | ''
export interface IFilters {
  start_date: string
  end_date: string
  fund?: string
  status?: IFilterStatus
  sended_spectrum?: boolean
}

export const SolicitudesTab = () => {
  const [search, setSearch] = useStateCallback<string>('')
  const debouncedSearch = useDebounce(search, 500)
  const [filters, setFilters] = useStateCallback<IFilters>({
    start_date: '',
    end_date: '',
    sended_spectrum: false,
  })

  const [errors, setErrors] = useStateCallback<any>({
    comment: null,
  })
  const [selectedStatus, setSelectedStatus] = useStateCallback<null | IPaymentStatusEnum>(null)
  const [selectedSolicitudes, setSelectedSolicitudes] = useStateCallback<number[]>([])
  const { hasPermission } = usePermissions()
  const {
    selectedSuscriptions,
    insertSolicitudesMutation,
    setSelectedSuscriptions,
    comment,
    setComment,
    changeStatusMutation,
  } = useSolicitudes()

  const handleMutation = () => {
    customSwal
      .fire({
        title: `¿Está seguro que desea ingresar las solicitudes a SPECTRUM?`,
        icon: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: `Sí, cambiar`,
        cancelButtonText: 'Cancelar',
      })
      .then((result) => {
        if (result.isConfirmed) {
          insertSolicitudesMutation.mutate()
        }
      })
  }

  const {
    exportMovements
  } = useSolicitudes()

  const handleExcelDownload = () => {
    let ex = {
      filter: filters,
      search: search
    } as IExportClient;
    exportMovements.mutate(ex);
  }

  useEffect(() => {
    if (changeStatusMutation.isSuccess && selectedStatus === IPaymentStatusEnum.ANNULLED) {
      $('#comment-section').modal('toggle')
      setComment('')
      setErrors({
        comment: 'Campo requerido',
      })
    }
  }, [changeStatusMutation.isSuccess])

  return (
    <>
      <div className='card border-0 pt-5 border-radius-20px'>
        <div className='card-header border-bottom-0 mt-3'>
          <div className='d-flex align-items-center gap-5'>
            <SearchInput
              placeholder='🔍︎  Buscar movimiento'
              search={search}
              setSearch={setSearch}
            />
            {selectedSuscriptions.length !== 0 && (
              <span className='text-muted d-block fs-6'>
                {selectedSuscriptions.length} Seleccionado{selectedSuscriptions.length > 1 && 's'}
              </span>
            )}
          </div>
          <button
              style={{
                backgroundColor: '#007BC3',
                color: 'white',
                width: '180px',
                height: '42px',
              }}
              onClick={handleExcelDownload}
              className='btn text-end cursor-pointer w-200px'
            >
              <img
                style={{
                  marginRight: '5px',
                }}
                src={toAbsoluteUrl('/✅ Web - Mm/donwload.svg')}
                alt='plus'
                className='w-15px  '
              />
              Exportar Movimientos
            </button>
        </div>
        <Export />
        <div className='modal fade p-5 rounded-4' tabIndex={-1} id='comment-section' role='dialog'>
          <div className='modal-dialog rounded-4 modal-dialog-centered' role='document'>
            <div className='modal-content'>
              <div className='modal-header py-5 mt-2 fw-bolder'>
                <h1 className='text-alert'>Motivo de rechazo</h1>
              </div>

              <div className='modal-body'>
                <label className='text-muted fs-6 mb-2'>
                  Por favor ingrese el motivo de rechazo para {selectedSuscriptions.length}{' '}
                  solicitud{selectedSuscriptions.length > 1 && 'es'} seleccionada
                  {selectedSuscriptions.length > 1 && 's'}:
                </label>
                <textarea
                  className='form-control form-control-solid mb-5'
                  name='export_range'
                  onChange={(e) => setComment(e.target.value)}
                  style={{ resize: 'none' }}
                  placeholder='Comentario'
                  value={comment}
                  onKeyUp={(e) => {
                    if (e.currentTarget.value.trim() === '') {
                      setErrors({
                        comment: 'Campo requerido',
                      })
                    } else if (e.currentTarget.value.trim().length < 2) {
                      setErrors({
                        comment: 'Debe contener mínimo dos caracteres',
                      })
                    } else {
                      setErrors({
                        comment: null,
                      })
                    }
                  }}
                  rows={5}
                />
                {errors['comment'] && (
                  <span className='text-danger' role='alert'>
                    {errors['comment']}
                  </span>
                )}
              </div>
              <div className='modal-footer py-2'>
                <div className='text-end'>
                  <button
                    style={{ backgroundColor: '#eee', color: '#969696' }}
                    type='button'
                    id='cancelButton'
                    className='btn btn col m-3'
                    data-bs-dismiss='modal'
                  >
                    Cancelar
                  </button>
                  <button
                    style={{
                      background: 'rgb(0, 123, 195)',
                      color: 'white',
                    }}
                    disabled={
                      errors['comment'] !== null ||
                      comment.trim() === '' ||
                      changeStatusMutation.isLoading
                    }
                    type='button'
                    className='btn btn col m-3 fw'
                    onClick={() =>
                      changeStatusMutation.mutate({
                        status: IPaymentStatusEnum.ANNULLED as unknown as IFilterStatus,
                        selectedSolicitudes,
                      })
                    }
                  >
                    <div className='d-flex gap-3 align-items-center'>Confirmar</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {hasPermission([
          Permissions.REQUEST_PENDING.CANCEL,
          Permissions.REQUEST_PENDING.SPECTRUM,
        ]) && (
          <>
            {selectedSuscriptions.length !== 0 && (
              <div
                style={{ border: '1px solid #eee' }}
                className='card-body rounded position-relative d-flex align-items-center justify-content-between w-300px mx-9 py-2'
              >
                <span className='text-muted' style={{ fontWeight: 600 }}></span>
                {hasPermission(Permissions.REQUEST_PENDING.CANCEL) && (
                  <StatusList
                    setSelectedStatus={setSelectedStatus}
                    mutationLoading={changeStatusMutation.isLoading}
                    setComment={setComment}
                    solicitude
                  />
                )}
                {hasPermission(Permissions.REQUEST_PENDING.SPECTRUM) && (
                  <div className='d-flex' style={{ marginLeft: 12 }}>
                    <button
                      disabled={insertSolicitudesMutation.isLoading}
                      style={{
                        border: 'none',
                      }}
                      onClick={() => handleMutation()}
                      className='flex-grow-1 badge-light badge rounded py-2 px-3'
                    >
                      <div className='d-flex align-items-center '>
                        <div>Ingresar solicitud a spectrum</div>
                      </div>
                    </button>
                  </div>
                )}
                <div
                  onClick={() => setSelectedSuscriptions([])}
                  style={{
                    borderBottom: '1px inset #a1a5b7',
                    position: 'absolute',
                    right: '-130px',
                  }}
                  className='text-muted cursor-pointer'
                >
                  Limpiar selección
                </div>
              </div>
            )}
          </>
        )}
        <SolicitudesTable
          search={search.length > 2 ? debouncedSearch! : ''}
          setSelectedSuscriptions={setSelectedSuscriptions}
          selectedSuscriptions={selectedSuscriptions}
          setSearch={setSearch}
          setFilters={setFilters}
          filters={filters}
          mutationSuccess={insertSolicitudesMutation.isSuccess || changeStatusMutation.isSuccess}
          setSelectedSolicitudes={setSelectedSolicitudes}
        />
      </div>
      <Filter setFilters={setFilters} filters={filters} />
    </>
  )
}
