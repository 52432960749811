import { IExportClient } from '../common/interfaces'
import { apiUrl } from '../config/api'
import { IFilters, IFilterStatus } from '../modules/movements/components/tabs/SuscriptionTab'

export const SuscriptionService = () => {
  const listSubscription = (
    page: number | string,
    limit: number | string,
    search: string,
    filters: IFilters
  ) =>
    apiUrl
      .post(`suscription/list?page=${page}&limit=${limit}`, { search, filter: filters })
      .then((res: any) => res.data)

  const changeStatusSubscription = (
    status: IFilterStatus,
    selectedSuscriptions: number[],
    comment: string
  ) =>
    apiUrl
      .post(`/suscription/change-status`, {
        ids: selectedSuscriptions,
        status,
        comment,
      })
      .then((res: any) => res.data)
  const updateSubscription = (data: { subscription_id: number; collector_account_id: number }) =>
    apiUrl.patch(`/suscription/update-subscription`, data).then((res: any) => res.data)

  const downloadAssets = (id: number | string) =>
    apiUrl.get(`/payment/suscription/${id}?limit=100`).then((res) => res.data)

  const suscriptionsByUser = (customer_id: string | string) =>
    apiUrl
      .post(`/suscription/list-collector-account-customer/${customer_id}`)
      .then((res) => res.data)

  const getRangeData = (dateFilter: IFilters) =>
    apiUrl.post(`suscription/list`, { filter: dateFilter }).then(async (res: any) => {
      return res.data
    })

  const exportMovements = (
      req: IExportClient
    ) =>
      apiUrl
        .post(`/suscription/export`, {
          search: req.search,
          filter: req.filter,
        },{
          responseType: 'blob',
          headers: {
            'Document-Type': 'xlsx'
          }
        })
        .then((res) => res.data)

  return {
    listSubscription,
    changeStatusSubscription,
    downloadAssets,
    suscriptionsByUser,
    getRangeData,
    updateSubscription,
    exportMovements
  }
}
