import { useMutation } from '@tanstack/react-query'
import { saveAs } from 'file-saver'
import { useStateCallback } from '../../../common/hooks/useStateCallback'
import { customSwal } from '../../../config/swal'
import { PaymentService } from '../../../services'
import { SuscriptionService } from '../../../services/SuscriptionService'
import { IFilterStatus } from '../components/tabs/SuscriptionTab'
import { IExportClient } from '../../../common/interfaces'
import { openFile } from '../../../common/helpers/ExportHandlers'

export const useSolicitudes = () => {
  const [selectedSuscription, setSelectedSuscription] = useStateCallback<number>(0)
  const [selectedSuscriptions, setSelectedSuscriptions] = useStateCallback<number[]>([])
  const [comment, setComment] = useStateCallback<string>('')

  const changeStatusMutation = useMutation({
    mutationFn: ({
      status,
      selectedSolicitudes,
    }: {
      status: IFilterStatus
      selectedSolicitudes: number[]
    }) => SuscriptionService().changeStatusSubscription(status, selectedSolicitudes, comment),
    onSuccess: () => {
      customSwal.fire({
        icon: 'success',
        title: `Estado actualizado para ${selectedSuscriptions.length} ${
          selectedSuscriptions.length > 1 ? 'suscripciones' : 'suscripción'
        }.`,
      })
      setSelectedSuscriptions([])
    },
  })
  const insertSolicitudesMutation = useMutation({
    mutationFn: () => PaymentService().insertSolicitudes(selectedSuscriptions),
    onSuccess: () => {
      customSwal.fire({
        icon: 'success',
        title: `Estado actualizado para ${selectedSuscriptions.length} ${
          selectedSuscriptions.length > 1 ? 'suscripciones' : 'suscripción'
        }.`,
      })
      setSelectedSuscriptions([])
    },
  })

  const downloadMutation = useMutation({
    mutationFn: (id: number | string) => SuscriptionService().downloadAssets(id),
    onSuccess: (res) => {
      downloadVoucher(res.data)
      setSelectedSuscription(0)
    },
  })

  const downloadVoucher = async (data: any) => {
    if (data.length === 0) {
      customSwal.fire({
        icon: 'warning',
        title: 'No se encontraron adjuntos para esta suscripción.',
      })
      return
    }
    let payment = data.reduce((prev: any, current: any) => (prev.id > current.id ? prev : current))
    if (payment) {
      saveAs(
        payment.voucher,
        payment.voucher.slice(payment.voucher.lastIndexOf('/') + 1, payment.voucher.length)
      )
    }
  }

  const previewImage = async (voucher: any, elementId: string) => {
    const res = await fetch(voucher)
    const blob = await res.blob()

    const img = new Image()
    img.src = URL.createObjectURL(blob)

    img.width = 400
    img.height = 800
    document.getElementById(elementId)!.append(img)
    await img.decode()

    URL.revokeObjectURL(img.src)
  }

  const exportMovements = useMutation(
    (req: IExportClient) => SuscriptionService().exportMovements(req),
    {
      onSuccess: (d: any) => {
        openFile(d, 'Listado de movimientos');
      },
    }
  );

  return {
    changeStatusMutation,
    downloadMutation,
    selectedSuscriptions,
    setSelectedSuscriptions,
    selectedSuscription,
    setSelectedSuscription,
    previewImage,
    insertSolicitudesMutation,
    comment,
    setComment,
    exportMovements
  }
}
