/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { SearchInput } from '../../../../common/components/SearchInput'
import { useDebounce } from '../../../../common/hooks/useDebounce'
import { useStateCallback } from '../../../../common/hooks/useStateCallback'
import { IExportClient, IPaymentStatusEnum } from '../../../../common/interfaces'
import { useRescues } from '../../hooks/useRescues'
import { Export } from '../Export'
import { Filter } from '../Filter'
import { StatusList } from '../StatusList'
import { RescueTable } from '../tables/RescueTable'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'

export const MAX_SELECTED_RESCUES = 5
export type IFilterStatus = '-1' | '0' | '1' | '2' | ''
export interface IFilters {
  start_date: string
  end_date: string
  fund?: string
  status?: IFilterStatus
}

export const RescueTab = () => {
  const [search, setSearch] = useStateCallback<string>('')
  const debouncedSearch = useDebounce(search, 500)

  const [filters, setFilters] = useStateCallback<IFilters>({
    start_date: '',
    end_date: '',
  })
  const [errors, setErrors] = useStateCallback<any>({
    comment: null,
  })
  const {
    changeStatusMutation,
    comment,
    setComment,
    selectedRescues,
    setSelectedRescues,
    selectedStatus,
    setSelectedStatus,
    exportRescues
  } = useRescues()

  useEffect(() => {
    if (changeStatusMutation.isSuccess && selectedStatus === IPaymentStatusEnum.ANNULLED) {
      $('#comment-section').modal('toggle')
      setComment('')
    }
  }, [changeStatusMutation.isSuccess])

  const handleExcelDownload = () => {
    let ex = {
      filter: filters,
      search: search
    } as IExportClient;
    exportRescues.mutate(ex);
  }

  return (
    <>
      <div className='card border-0 pt-5 border-radius-20px'>
        <div className='card-header border-bottom-0 mt-3'>
          <div className='d-flex align-items-center gap-5'>
            <SearchInput
              placeholder='🔍︎  Buscar movimiento'
              search={search}
              setSearch={setSearch}
            />
            <div
              style={{ background: 'rgb(0, 123, 195)' }}
              className='rounded p-2 d-flex justify-content-center align-items-center cursor-pointer'
              data-bs-toggle='modal'
              data-bs-target='#filter-subscriptions'
            >
              <i className='bi bi-filter fs-2 text-white'></i>
            </div>
            {selectedRescues.length !== 0 && (
              <span className='text-muted d-block fs-6'>
                {selectedRescues.length} Seleccionado{selectedRescues.length > 1 && 's'}
              </span>
            )}
            {!Object.values(filters).every((e) => e === '') && (
              <span
                style={{
                  borderBottom: '1px inset #a1a5b7',
                }}
                onClick={() =>
                  setFilters({
                    start_date: '',
                    end_date: '',
                    status: '',
                    fund: '',
                  })
                }
                className='text-muted cursor-pointer d-block fs-6'
              >
                Limpiar filtros
              </span>
            )}
          </div>
          <button
              style={{
                backgroundColor: '#007BC3',
                color: 'white',
                width: '180px',
                height: '42px',
              }}
              onClick={handleExcelDownload}
              className='btn text-end cursor-pointer w-200px'
            >
              <img
                style={{
                  marginRight: '5px',
                }}
                src={toAbsoluteUrl('/✅ Web - Mm/donwload.svg')}
                alt='plus'
                className='w-15px  '
              />
              Exportar Movimientos
            </button>
        </div>
        <Export />
        <div className='modal fade p-5 rounded-4' tabIndex={-1} id='comment-section' role='dialog'>
          <div className='modal-dialog rounded-4 modal-dialog-centered' role='document'>
            <div className='modal-content'>
              <div className='modal-header py-5 mt-2 fw-bolder'>
                <h1 className='text-alert'>Motivo de rechazo</h1>
              </div>

              <div className='modal-body'>
                <label className='text-muted fs-6 mb-2'>
                  Por favor ingrese el motivo de rechazo para {selectedRescues.length} suscripción
                  seleccionada{selectedRescues.length > 1 && 's'}:
                </label>
                <textarea
                  className='form-control form-control-solid mb-5'
                  name='export_range'
                  onChange={(e) => setComment(e.target.value)}
                  style={{ resize: 'none' }}
                  placeholder='Comentario'
                  value={comment}
                  onKeyUp={(e) => {
                    if (e.currentTarget.value.trim() === '') {
                      setErrors({
                        amount: 'Campo requerido',
                      })
                    } else {
                      setErrors({
                        amount: null,
                      })
                    }
                  }}
                  rows={5}
                />
                {errors['amount'] && (
                  <span className='text-danger' role='alert'>
                    {errors['amount']}
                  </span>
                )}
              </div>
              <div className='modal-footer py-2'>
                <div className='text-end'>
                  <button
                    style={{ backgroundColor: '#eee', color: '#969696' }}
                    type='button'
                    id='cancelButton'
                    className='btn btn col m-3'
                    data-bs-dismiss='modal'
                    onClick={() => setComment('')}
                  >
                    Cancelar
                  </button>
                  <button
                    style={{
                      background: 'rgb(0, 123, 195)',
                      color: 'white',
                    }}
                    disabled={errors['amount'] !== null || changeStatusMutation.isLoading}
                    type='button'
                    className='btn btn col m-3 fw'
                    onClick={() =>
                      changeStatusMutation.mutate(
                        IPaymentStatusEnum.ANNULLED as unknown as IFilterStatus
                      )
                    }
                  >
                    <div className='d-flex gap-3 align-items-center'>Confirmar</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {selectedRescues.length !== 0 && (
          <div
            style={{ border: '1px solid #eee' }}
            className='card-body rounded position-relative d-flex align-items-center justify-content-between w-400px mx-9 py-2'
          >
            <span className='text-muted' style={{ fontWeight: 600 }}>
              Cambiar a:
            </span>
            <StatusList
              rescue
              mutate={changeStatusMutation.mutate}
              selectedRescueState={selectedRescues[0]?.status}
              setSelectedStatus={setSelectedStatus}
              mutationLoading={changeStatusMutation.isLoading}
            />
            <div
              onClick={() => setSelectedRescues([])}
              style={{
                borderBottom: '1px inset #a1a5b7',
                position: 'absolute',
                right: '-130px',
              }}
              className='text-muted cursor-pointer'
            >
              Limpiar selección
            </div>
          </div>
        )}
        <RescueTable
          search={search.length > 2 ? debouncedSearch! : ''}
          setSearch={setSearch}
          selectedRescues={selectedRescues}
          setSelectedRescues={setSelectedRescues}
          filters={filters}
          setFilters={setFilters}
          mutationSuccess={changeStatusMutation.isSuccess}
        />
      </div>
      <Filter rescue setFilters={setFilters} filters={filters} />
    </>
  )
}
