/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, { FC, useEffect } from 'react'
import { BackofficePermissionsEnum as Permissions } from '../../../../common/enums/backOfficePermissionsEnum'
import { formatDateTimeUTC, formatTimeToHHMMSS } from '../../../../common/helpers/DateHandlers'
import { usePaginator } from '../../../../common/hooks/usePaginator'
import usePermissions from '../../../../common/hooks/usePermissions'
import { useStateCallback } from '../../../../common/hooks/useStateCallback'
import {
  IRescue,
  ISelectedRescue,
  MoneyTypeEnum,
  RescueStatusEnum,
} from '../../../../common/interfaces/movement.interface'
import { RescueService } from '../../../../services/RescueService'
import { formatCustomerFulllname } from '../../../clients/helpers/customerFormatHelper'
import { RescueDetailCollapsible } from '../collapsible/RescueDetailCollapsible'
import { IFilters } from '../tabs/RescueTab'

const pageSizes = [10, 20, 40, 100]

interface IProps {
  search: string
  setSearch: (state: string, cb?: ((state: string) => void) | undefined) => void
  selectedRescues: ISelectedRescue[]
  setSelectedRescues: (state: ISelectedRescue[], cb?: (state: ISelectedRescue[]) => void) => void
  filters: IFilters
  setFilters: (state: IFilters, cb?: ((state: IFilters) => void) | undefined) => void
  mutationSuccess: boolean
}

export const RescueTable: FC<IProps> = ({
  search,
  setSearch,
  selectedRescues,
  setSelectedRescues,
  filters,
  setFilters,
  mutationSuccess,
}) => {
  const [currentRescue, setCurrentRescue] = useStateCallback<number>(0)
  const [activesRescues, setActivesRescues] = useStateCallback<any>([])
  const { hasPermission } = usePermissions()
  const {
    limit,
    setPage,
    setLimit,
    paginationComponent,
    query: {
      data: rescues,
      refetch,
      isRefetching,
      isLoading: isLoadingRescues,
      isError,
      isFetching,
    },
  } = usePaginator({
    initPage: 1,
    initLimit: 10,
    search,
    pageSizes,
    paginatorOptions: {
      queryKey: ['rescues-list'],
      fetch: RescueService().listRescues,
    },
    queryOptions: {
      retry: false,
    },
    filters,
  })

  useEffect(() => {
    setPage(1)
    refetch()
  }, [JSON.stringify(filters), mutationSuccess, search])

  useEffect(() => {
    setPage(1)
    refetch()
  }, [limit, refetch])

  const cleanupRequest = () => {
    setPage(1)
    setLimit(10)
    setSearch('')
    setFilters(
      {
        start_date: '',
        end_date: '',
      },
      () => {
        refetch()
      }
    )
  }

  const updateSelectedRescues = (rescue: ISelectedRescue) => {
    const getRescue = () => {
      const targetRescue = selectedRescues.find((o) => o.id === rescue.id)
      if (targetRescue) {
        const filteredRescue = selectedRescues.filter((o) => o.id !== rescue.id)
        return filteredRescue
      }
      // ** There's only one rescue selected at a time. **
      return [rescue]
    }
    setSelectedRescues(getRescue())
  }

  useEffect(() => {
    $(function () {
      $('[data-bs-toggle="tooltip"]').tooltip()
    })
  }, [])

  return (
    <div className='card-body'>
      <div className='table-responsive'>
        {((isLoadingRescues || isFetching) && !isError) || isRefetching ? (
          <div className='text-center m-5 p-10'>
            <div className='spinner-border' role='status'></div>
          </div>
        ) : (
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            <thead>
              <tr className='fw-bolder text-muted'>
                {hasPermission(Permissions.RESCUE.CHANGE_STATUS) && <th className='w-75px'></th>}
                <th className='min-w-100px'>TIPO</th>
                <th className='min-w-100px'>SUB TIPO</th>
                <th className='min-w-100px'>TRASPASO</th>
                <th className='min-w-150px'>FECHA Y HORA</th>
                <th className='min-w-120px'>CLIENTE</th>
                <th className='min-w-150px'>FONDO</th>
                <th className='min-w-120px'>MONTO</th>
                <th className='min-w-150px'>CANAL</th>
                <th className='min-w-150px'>MEDIO</th>
                <th className='min-w-100px'>N° CUOTAS</th>
                <th className='min-w-150px'>CUENTA DESTINO</th>
                <th className='min-w-150px new-tooltip'>
                  <span className='tooltiptext'>Código de cuenta interbancario</span>
                  CCI
                </th>

                <th className='min-w-150px'>BANCO DESTINO</th>
                <th className='min-w-120px'>ESTADO</th>
                <th className='w-75px'></th>
              </tr>
            </thead>

            <tbody>
              {rescues?.data &&
                rescues
                  ?.data!.sort(
                    (a: IRescue, b: IRescue) =>
                      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
                  )
                  .map((rescue: IRescue, index: number) => {
                    const newDate = rescue.constancy_date
                      ? new Date(rescue.constancy_date)
                      : new Date(rescue.created_at)
                    return (
                      <React.Fragment key={rescue.id}>
                        <tr
                          className='collapsible cursor-pointer '
                          onClick={() => {
                            setActivesRescues((prev: number[]) => {
                              const index = prev.indexOf(rescue.id)
                              if (index === -1) {
                                return [...prev, rescue.id]
                              }
                              return [...prev.slice(0, index), ...prev.slice(index + 1)]
                            })
                            setCurrentRescue(rescue.id)
                          }}
                          key={index}
                        >
                          {hasPermission(Permissions.RESCUE.CHANGE_STATUS) && (
                            <td className='text-center'>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  visibility:
                                    (rescue.status === RescueStatusEnum.INPROGRESS &&
                                      rescue.details_ids.length) ||
                                    rescue.status === RescueStatusEnum.PENDING
                                      ? 'visible'
                                      : 'hidden',
                                }}
                                className='form-check form-check-custom form-check-solid'
                              >
                                <input
                                  style={{
                                    width: '24px',
                                    height: '24px',
                                    cursor: 'pointer',
                                  }}
                                  className='form-check-input'
                                  type='checkbox'
                                  id='flexCheckDefault'
                                  checked={selectedRescues.some((o) => o.id === rescue.id)}
                                  onClick={(e) => {
                                    e.stopPropagation()
                                  }}
                                  onChange={(e) => {
                                    e.stopPropagation()
                                    updateSelectedRescues(rescue)
                                  }}
                                />
                              </div>
                            </td>
                          )}

                          <td>
                            {rescue.request_type ? (
                              <span className='badge badge-light p-3'>{rescue.request_type}</span>
                            ) : (
                              <span className='text-muted d-block fs-5'>
                                {rescue.request_type || '--'}
                              </span>
                            )}
                          </td>

                          <td>
                            <span className='text-muted d-block fs-5'>
                              {rescue.rescue_type || '--'}
                            </span>
                          </td>

                          <td>
                            <span className='text-muted d-block fs-5'>
                              {rescue.internal_transfer ? 'Sí' : 'No'}
                            </span>
                          </td>

                          <td>
                            <span className='text-muted d-block fs-5'>
                              {formatDateTimeUTC(newDate.toDateString())}
                            </span>
                            <span className='text-muted d-block fs-5'>
                              {formatTimeToHHMMSS(newDate)}
                            </span>
                          </td>
                          <td>
                            <span className='text-muted d-block fs-5'>
                              {formatCustomerFulllname(rescue.customer)}
                            </span>
                          </td>
                          <td>
                            <span className='text-muted d-block fs-5'>
                              {rescue.fund.title} - {rescue.serie.name}
                            </span>
                          </td>
                          <td>
                            <span className='text-muted d-block fs-5 monto-completo'>
                              {rescue.money_type === MoneyTypeEnum.USD
                                ? '$'
                                : rescue.money_type === MoneyTypeEnum.PEN
                                ? 'S/.'
                                : '-'}
                              {rescue.subtotal?.toFixed(4)}
                            </span>
                          </td>

                          <td>
                            <span className='text-muted d-block fs-5'>
                              {rescue.channel_register ?? '--'}
                            </span>
                          </td>
                          <td>
                            <span className='text-muted d-block fs-5'>
                              {[
                                rescue.utm_source,
                                rescue.utm_medium,
                                rescue.utm_campaign,
                              ]
                                .filter(Boolean) // filter out any falsy values and join the rest
                                .join(', ') || '--'}
                            </span>
                          </td>

                          <td>
                            <span className='text-muted d-block fs-5'>
                              {rescue.installments_quantity?.toFixed(4) || '--'}
                            </span>
                          </td>
                          <td>
                            <span className='text-muted d-block fs-5'>
                              {rescue.bank_account?.account_number || '--'}
                            </span>
                          </td>
                          <td>
                            <span className='text-muted d-block fs-5'>
                              {rescue.bank_account?.cci || '--'}
                            </span>
                          </td>
                          <td>
                            <span className='text-muted d-block fs-5'>
                              {rescue.user_account_json.bank_account?.bank_name || '--'}
                            </span>
                          </td>
                          <td>
                            {rescue.status === RescueStatusEnum.INPROGRESS ? (
                              <span className='badge badge-light-primary'>En proceso</span>
                            ) : rescue.status === RescueStatusEnum.APPROVED ? (
                              <span className='badge badge-light-success'>Aprobado</span>
                            ) : rescue.status === RescueStatusEnum.PENDING ? (
                              <span className='badge badge-light-warning'>Pendiente</span>
                            ) : (
                              rescue.status === RescueStatusEnum.ANNULLED && (
                                <span className='badge badge-light-danger'>Anulado</span>
                              )
                            )}
                          </td>
                          <td className='text-center'>
                            {activesRescues.includes(rescue.id) ? (
                              <i className={clsx('bi bi-chevron-up fs-5')}></i>
                            ) : (
                              <i className={clsx('bi bi-chevron-down fs-5')}></i>
                            )}
                          </td>
                        </tr>
                        <tr className='text-center px-20'>
                          {activesRescues.includes(rescue.id) && (
                            <RescueDetailCollapsible
                              selectedRescues={selectedRescues}
                              setSelectedRescues={setSelectedRescues}
                              rescue_id={rescue.id}
                              current_rescue={currentRescue}
                              money_type={
                                rescue.money_type === MoneyTypeEnum.USD
                                  ? '$'
                                  : rescue.money_type === MoneyTypeEnum.PEN && 'S/.'
                              }
                              mutationSuccess={mutationSuccess}
                            />
                          )}
                        </tr>
                      </React.Fragment>
                    )
                  })}
            </tbody>
          </table>
        )}
        {rescues?.data.length === 0 &&
          !(((isLoadingRescues || isFetching) && !isError) || isRefetching) && (
            <span className='notFound text-muted text-muted fs-5 text-center mt-8 mb-8'>
              No se encontraron resultados{' '}
              <a
                className='link'
                style={{
                  marginLeft: 5,
                  cursor: 'pointer',
                }}
                onClick={cleanupRequest}
              >
                refrescar la tabla
              </a>
            </span>
          )}
      </div>
      {paginationComponent()}
    </div>
  )
}
