/* eslint-disable react-hooks/exhaustive-deps */
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { SearchInput } from '../../../../common/components/SearchInput'
import { useDebounce } from '../../../../common/hooks/useDebounce'
import { useStateCallback } from '../../../../common/hooks/useStateCallback'
import { IExportClient, IPaymentStatusEnum } from '../../../../common/interfaces'
import { useSolicitudes } from '../../hooks/useSolicitudes'
import { Filter } from '../Filter'
import { AnnulledTable } from '../tables/AnnulledTable'

export type IFilterStatus = '-1' | '0' | '1' | '2' | ''
export interface IFilters {
  start_date: string
  end_date: string
  fund?: string
  status?: IFilterStatus
  sended_spectrum?: boolean
}

export const AnnulledTab = () => {
  const [search, setSearch] = useStateCallback<string>('')
  const debouncedSearch = useDebounce(search, 500)
  const [filters, setFilters] = useStateCallback<IFilters>({
    start_date: '',
    end_date: '',
    sended_spectrum: undefined,
    status: IPaymentStatusEnum.ANNULLED as unknown as IFilterStatus,
  })

  const {
    exportMovements
  } = useSolicitudes()

  const handleExcelDownload = () => {
    let ex = {
      filter: filters,
      search: search
    } as IExportClient;
    exportMovements.mutate(ex);
  }

  return (
    <>
      <div className='card border-0 pt-5 border-radius-20px'>
        <div className='card-header border-bottom-0 mt-3'>
          <div className='d-flex align-items-center gap-5'>
            <SearchInput
              placeholder='🔍︎  Buscar movimiento'
              search={search}
              setSearch={setSearch}
            />
          </div>
          <button
              style={{
                backgroundColor: '#007BC3',
                color: 'white',
                width: '180px',
                height: '42px',
              }}
              onClick={handleExcelDownload}
              className='btn text-end cursor-pointer w-200px'
            >
              <img
                style={{
                  marginRight: '5px',
                }}
                src={toAbsoluteUrl('/✅ Web - Mm/donwload.svg')}
                alt='plus'
                className='w-15px  '
              />
              Exportar Movimientos
            </button>
        </div>
        <AnnulledTable
          search={search.length > 2 ? debouncedSearch! : ''}
          setSearch={setSearch}
          setFilters={setFilters}
          filters={filters}
        />
      </div>
      <Filter setFilters={setFilters} filters={filters} />
    </>
  )
}
